@import '../../../variables';

.daily-reward-container {
  background: linear-gradient(180deg, $primaryBackgroundColorBlue 0%, $primaryBackgroundColorBlack 100%);

  .daily-reward {

    .daily-reward-wheel-container {
      display: flex;
      justify-content: center;

      .daily-reward-wheel-image {
        max-width: 100%;
      }
    }

    .daily-reward-reward-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .daily-reward-reward {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 80%;

        .daily-reward-reward-picture {

          .daily-reward-image {
            max-width: 300px;
            max-height: 100%;
          }
        }

        .daily-reward-label {
          color: white;
        }
      }

      .daily-reward-action {
        height: 20%;
      }
    }
  }
}