@import './../../../../variables';

.modal {
  &.modal-gradiant {
    --bs-modal-color: #fff;
    --bs-modal-bg: #{$primaryColor2};
    --bs-modal-border-color: #{$primaryColor2};

    --bs-modal-header-border-color: #{$primaryColor2};

    .modal-content {
      background: linear-gradient(180deg, $primaryBackgroundColorBlue 0%, $primaryBackgroundColorBlack 100%);

      .modal-header {
        .modal-title {

        }

        .btn-close {
          filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);

          &:hover {
            filter: invert(0.5) sepia(1) saturate(5) hue-rotate(15deg);
          }
        }
      }
    }
  }
}
