@import '../../../../variables';

.player-group-team-player-info {
  margin-top: 0.25rem;

  .player-group-team-player-info-label {
    color: $lightGrey;
  }

  .player-group-team-player-info-details {
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.375rem;

    .player-group-team-player-info-detail-pseudo {
      text-align: center;
    }



    .player-group-team-player-info-detail-level {
      margin-top: 0.5rem;
      text-align: center;
    }

    .player-group-team-player-info-detail-energy {
      margin-top: 0.5rem;
      text-align: center;
    }

    .player-group-team-player-info-detail-weekly-progression {
      margin-top: 0.5rem;
      text-align: center;
    }
  }

  .player-group-team-player-info-actions-and-information {
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.375rem;

    .player-group-team-player-info-actions-and-information-tabs {
      --bs-nav-tabs-border-color: #{$primaryColor2};

      --bs-nav-tabs-link-hover-color: #fff;
      --bs-nav-tabs-link-hover-border-color: #{$primaryColor2};

      --bs-nav-tabs-link-active-bg: #{$primaryColor1};
      --bs-nav-tabs-link-active-color: #fff;
      --bs-nav-tabs-link-active-border-color: #{$primaryColor2};

      .nav-item {
        .nav-link {
          &.active,
          &:hover {
            color: #fff;
            background: #{$primaryColor2};
            background: linear-gradient(205deg, $primaryColor1 20%, $primaryColor2 100%);
            background-repeat: no-repeat; // Fix for firefox 1px of background color displaying on the left
          }
        }
      }
    }

    .player-group-team-player-events {
      .player-group-team-player-event {
        display: flex;
        align-items: center;
        border-bottom: solid 0.06rem $lightGrey;
        padding: 0.5rem 0;

        .player-group-team-player-event-date {
          margin-right: 0.25rem;
          color: #{$lightGrey};
        }

        .player-group-team-player-event-label {

        }

        .player-group-team-player-event-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
          gap: 0.25rem 0.5rem;
          margin-left: 0.25rem;

          .player-group-team-player-event-action {

          }
        }
      }

      .player-group-team-player-no-event {
        padding: 0.5rem 0;
        color: #{$lightGrey};
      }
    }

    .player-group-team-player-info-information {
      padding: 0.5rem;

      .player-group-team-player-info-information-player-class {

      }

      .player-group-team-player-info-information-competence {
        margin-top: 0.5rem;
      }

      .player-group-team-player-info-information-objectives {
        display: flex;
        margin-top: 0.5rem;

        .player-group-team-player-info-information-objectives-label {
          width: 5rem;
          flex-shrink: 1;
          margin-right: 0.125rem;
        }

        .player-group-team-player-info-information-objective-list {
          width: 100%;

          .player-group-team-player-info-information-objective-list-item {
            padding: 0.25rem;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 0.375rem;

            .player-group-team-player-info-information-objective-list-item-category {

            }

            .player-group-team-player-info-information-objective-list-item-description {

            }
          }
        }
      }
    }
  }
}
