@import '../../../../variables';

$headerBlueColor: #02004c;
$headerBlackColor: #000000;

/* Header */
.main {
  .main-header {
    position: relative;
    height: 100px;
    margin: 0;
    padding: 0;
    color: white;
    background: $headerBlueColor;
    background: linear-gradient(180deg, $headerBlueColor 0%, $headerBlackColor 100%);

    .main-header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      .first-container {
        height: 100%;

        .main-logo-container {
          height: 100%;
          padding: 5px;

          .main-logo {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .second-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .energy-bar-container {
          padding: 0 10px;

          .energy-bar-label {
            display: block;
            text-align: center;
          }

          .progress-bar-component {
            width: 400px;
            height: 50px;
          }
        }

        .items-container {
          padding: 0 10px;

          .items {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .item {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex: 0 0 auto;
              width: auto;
              padding: 0 5px;

              .item-image {
                display: flex;
                max-width: 100%;
                max-height: 80px;
              }

              .item-label {
                display: flex;
                padding: 0 0 0 10px;
                font-size: 1.2rem;
              }
            }
          }
        }
      }

      .third-container {
        position: relative;
        width: 200px;
        height: 100px;

        .progress-bar-circular {
          position: absolute;
          top: 5px;
          right: 5px;
          z-index: 2;
        }
      }
    }
  }
}
