@import '../../../../variables';

$borderWidth: 10px;
$animationTime: 1.0s;
$border-color-default: $darkGrey;
$border-color-fill: $primaryColor2;
$size: 200px;
$howManySteps: 100; //this needs to be even.

.progress-bar-circular {
  width: $size;
  height: $size;
  line-height: $size;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  border-radius: 50%;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $borderWidth solid $border-color-default;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }

  > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: $borderWidth;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: $border-color-fill;
  }

  .progress-left {
    left: 0;
    z-index: 4;

    .progress-bar {
      left: 100%;
      border-top-right-radius: ($size/2);;
      border-bottom-right-radius: ($size/2);;
      border-left: 0;
      -webkit-transform-origin: center left;
      transform-origin: center left;
      //animation: loading-2 1.5s linear forwards 1.8s;
    }
  }

  .progress-right {
    right: 0;
    z-index: 4;

    .progress-bar {
      left: -100%;
      border-top-left-radius: ($size/2);;
      border-bottom-left-radius: ($size/2);;
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      //animation: loading-1 1.8s linear forwards;
    }
  }

  .progress-bar-circular-content {
    position: relative;
    display: flex;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 300;
    overflow: hidden;

    .progress-bar-circular-content-label {
      z-index: 2;
    }

    .progress-bar-circular-content-image {
      position: absolute;
      top: $borderWidth;
      right: $borderWidth;
      left: $borderWidth;
      bottom: $borderWidth;
      max-width: calc(100% - ($borderWidth * 2));
      z-index: 1;
    }
  }

  &.progress-bar-circular-label-on-hover {
    .progress-bar-circular-content-label {
      display: none;
    }

    &:hover {
      .progress-bar-circular-content-label {
        display: block;
      }
    }
  }

  &.progress-bar-circular-link {
    cursor: pointer;

    &:hover {
      box-shadow: 0 -4px 20px $border-color-fill;
    }
  }
}

/* This for loop creates the necessary css animation names
Due to the split circle of progress-left and progress right, we must use the animations on each side.
*/
@for $i from 1 through $howManySteps {
  $stepName: ($i*(100 / $howManySteps));

  //animation only the left side if below 50%
  @if $i <= ($howManySteps/2) {
    .progress-bar-circular[data-percentage="#{$stepName}"] {
      .progress-right .progress-bar {
        animation: loading-#{$i} $animationTime linear forwards;
      }
      .progress-left .progress-bar {animation: 0;}
    }
  }
  //animation only the right side if above 50%
  @if $i > ($howManySteps/2)  {
    .progress-bar-circular[data-percentage="#{$stepName}"] {
      .progress-right .progress-bar {
        animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
      }
      .progress-left .progress-bar {
        animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
      }
    }
  }
}

//animation
@for $i from 1 through ($howManySteps/2) {
  $degrees: (180/($howManySteps/2));
  $degrees: ($degrees*$i);
  @keyframes loading-#{$i}{
    0%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(#{$degrees}deg);
    }
  }
}