@import '../../../../variables';

.player-profile-modal {

  .player-profile-resume {

    .player-profile-resume-infos {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 0.375rem;
    }

    .player-info-label {
      color: #9f9fa5;
    }
  }

}
