body {
  .main {
    .main-content.home {
      //display: block;
      //overflow: scroll;

      .main-background-image-container {
        background-color: white;

        .main-background-image {
          //max-width: 100%;
          //max-height: 100%;
          opacity: 0.75;
        }
      }
    }
  }
}
