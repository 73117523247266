@import './../../../variables';

.coach-console {
  .main-app-coach-logo {
    display: block;
    padding: 1rem;
    cursor: pointer;

    .main-logo {
      max-width: 100%;
      max-height: 100%;
    }

    &:hover {
      filter: drop-shadow(5px 5px 5px $primaryColor2);
    }
  }

  .coach-console-menu {
    margin: 0 0.5rem;

    .coach-console-menu-item {
      .coach-console-menu-item-link {
        .coach-console-menu-item-icon {
          width: 20px;
          margin-right: 0.25rem;
        }

        .coach-console-menu-item-label {

        }
      }
    }
  }

  .coach-console-coach-user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 0;
    border-top: 0.0625rem solid $primaryColor2;

    .coach-console-coach-user-avatar {
      padding: 0.5rem;

      .user-logo {
        max-width: 5rem;
        max-height: 100%;
      }
    }

    .coach-console-coach-user-name {
      display: inline-flex;
      width: 100%;
      padding: 0.5rem;
    }
  }




  .coach-console-container {
    height: 100%;

    .coach-console-left-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      border-right: 0.0625rem solid $primaryColor2;
    }

    .coach-console-right-column {

      .coach-console-tab-content {
        margin: 0.5rem;
        padding: 0.5rem;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 0.375rem;

        .coach-console-tab-content-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.25rem;

          .coach-recommendation-list-header-left {
            display: flex;

            .coach-recommendation-list-title-container {
              .coach-recommendation-list-title {

              }
            }
          }
        }
      }
    }
  }
}
