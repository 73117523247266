@import '../../../../variables';

.player-group-container {
  .player-group-info {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 0.5rem 0.25rem;
    margin-bottom: 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.375rem;

    .player-group-info-image {
      position: relative;
      border-radius: 50rem;
      overflow: hidden;
      margin-right: 1rem;

      .player-group-info-imag-img {
        max-width: 100%;
        max-height: 100%;
        border: 3px solid #{$primaryColor2};
        border-radius: inherit;
      }
    }

    .player-group-info-name {
      display: flex;
      align-items: center;
      padding: 0.25rem;
      color: #{$primaryColor2};
      font-size: 1.5rem;
    }
  }

  .player-group-bonuses-and-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.375rem;

    .player-group-active-bonuses {
      display: flex;
      padding: 0.125rem 0;

      .player-group-active-bonuses-label {
        padding: 0 0.25rem;
        color: #{$lightGrey};
      }

      .player-group-active-bonuses-list {
        .badge {
          margin-right: 0.25rem;
        }
      }
    }

    .player-group-actions {
      .btn {
        margin-left: 0.5rem;
      }

      //.dropdown {
      //  .dropdown-menu {
      //    --bs-dropdown-bg: #{$primaryColor1};
      //    --bs-dropdown-border-color: #{$primaryColor2};
      //
      //    --bs-dropdown-link-color: #{$primaryColor2};
      //
      //    --bs-dropdown-link-hover-bg: #{$primaryColor2};
      //    --bs-dropdown-link-hover-color: #fff;
      //
      //    .dropdown-item {
      //      &:hover {
      //        background: #{$primaryColor2};
      //        background: linear-gradient(205deg, $primaryColor1 20%, $primaryColor2 100%);
      //        background-repeat: no-repeat; // Fix for firefox 1px of background color displaying on the left
      //      }
      //    }
      //  }
      //}
    }
  }

  .player-group-team {
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.375rem;
    margin-top: 1rem;

    .player-group-team-players {
      align-items: center;

      .player-group-team-player-action {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .player-group-history-and-leave-action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 5rem;

    .player-group-history {
      padding: 0.5rem;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 0.375rem;

      .player-group-history-label {
        margin-bottom: 0.5rem;
      }

      .player-group-history-items {
        .player-group-history-no-item {
          color: #{$lightGrey};
        }

        .player-group-history-item {
          display: flex;
          align-items: center;
          border-bottom: solid 0.06rem $lightGrey;
          padding: 0.5rem 0;

          .player-group-history-item-date {
            color: #{$lightGrey};
          }

          .player-group-history-item-player-pseudo {
            color: #{$lightGrey};
          }

          .player-group-history-item-message {
            //color: #{$lightGrey};
          }
        }
      }
    }

    .player-group-leave-action {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}