.card {
  .card-header {

  }

  .card-body {

  }

  .card-footer {

  }

  &.card-transparent {
    --bs-body-color: white;
    --bs-card-bg: rgba(255, 255, 255, 0.1);
    --bs-card-cap-bg: rgba(0, 0, 0, 0.2);
  }
}