@import './../../variables';

//@import url(https://fonts.googleapis.com/css?family=Rye);
/* latin-ext */
//@font-face {
//  font-family: 'Rye';
//  font-style: normal;
//  font-weight: 400;
//  src: url('./../../../../public/fonts/RyeLatinExt.woff2') format('woff2');
//  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
//}
///* latin */
//@font-face {
//  font-family: 'Rye';
//  font-style: normal;
//  font-weight: 400;
//  src: url('./../../../../public/fonts/RyeLatin.woff2') format('woff2');
//  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
//}

/* empowered game fonts */
@font-face {
  font-family: 'Empowered';
  font-style: normal;
  font-weight: 400;
  src: url('./../../../../public/fonts/abadi-mt-pro-bold.ttf') format('truetype');
}
@font-face {
  font-family: 'EmpoweredStylized';
  font-style: normal;
  font-weight: 400;
  src: url('./../../../../public/fonts/empowered_stylized.ttf') format('truetype');
}

body {

  .main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
    font-family: 'Empowered', sans-serif;

    .main-header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
    }

    .main-content {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      background: linear-gradient(180deg, $primaryBackgroundColorBlue 0%, $primaryBackgroundColorBlack 100%);
      overflow: hidden;

      .main-content-2 {
        flex: 1 1 auto;
        overflow: hidden;
      }

      .main-background-image-container {
        max-width: 100%;
        max-height: 100%;

        .main-background-image {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .main-overlay-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .main-page-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        .page-header {
        }

        .page-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex: 1 1 auto;
        }
      }
    }

    .main-footer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
    }
  }
}



//.Header {
//  z-index: 1;
//}
//
//.banner {
//  position: absolute;
//  z-index: auto;
//  left: 50%;
//  display: block;
//  margin: 100px -200px;
//  width: 400px;
//  height: 60px;
//  border: 1px solid #8a1;
//  font: normal 30px/60px 'Rye';
//  text-align: center;
//  color: #451;
//  background: #9b2;
//  border-radius: 4px;
//  box-shadow: 0 0 30px rgba(0,0,0,.15) inset,
//  0 6px 10px rgba(0,0,0,.15);
//}
//
//.banner::before,
//.banner::after {
//  content: '';
//  position: absolute;
//  z-index: -1;
//  left: -70px;
//  top: 24px;
//  display: block;
//  width: 40px;
//  height: 0px;
//  border: 30px solid #9b2;
//  border-right: 20px solid #791;
//  border-bottom-color: #94b81e;
//  border-left-color: transparent;
//  transform: rotate(-5deg);
//}
//
//.banner::after {
//  left: auto;
//  right: -70px;
//  border-left: 20px solid #791;
//  border-right: 30px solid transparent;
//  transform: rotate(5deg);
//}