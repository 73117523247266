@import './../../../variables';

.reward-items {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  //max-width: 500px;
  margin-bottom: 20px;

  .reward-line {
    display: flex;
    flex-wrap: wrap;

    .reward-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 1px;
      padding: 5px;
      background-color: rgba(0, 6, 129, 0.6);
      border: 1px solid rgba(0, 6, 129, 0.6);
      border-radius: 20px;

      .reward-item-label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        padding: 0 10px;
        background: linear-gradient(180deg, $btnPrimaryColor1 0%, $btnPrimaryColor2 100%);
        border-radius: 50%;
        z-index: 2;
      }

      .reward-item-image {
        max-width: 100%;
        max-height: 100%;
        z-index: 1;
      }

      &.reward-item-gems {
        .reward-item-image {
          margin-left: -18px;
        }
      }

      &.reward-item-gold-coins {
        .reward-item-image {
          margin-left: -28px;
        }
      }

      &.reward-item-experience {
        .reward-item-image {
        }
      }
    }
  }

  &.reward-items-small {
    //max-height: 60px;

    .reward-line {

      .reward-item {

        .reward-item-label {
          width: 30px;
          height: 30px;
          font-size: 70%;
        }

        .reward-item-image {
          max-width: 40px;
          max-height: 40px;
        }

        &.reward-item-gems {
          .reward-item-image {
            margin-left: -10px;
          }
        }

        &.reward-item-gold-coins {
          .reward-item-image {
            margin-left: -20px;
          }
        }

        &.reward-item-experience {
          .reward-item-image {
          }
        }
      }
    }
  }
}