@import '../../../../variables';

$howManySteps: 100;

.progress-bar-component {
  min-width: 200px;
  height: 30px;
  padding: 3px;
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid #fff;
  border-radius: 5rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  overflow: hidden;

  .progress-bar-component-progress {
    width: 0;
    height: 100%;
    background: #FCBC51 repeating-linear-gradient(
                    110deg,
                    #eaa02e 0px,
                    #eaa02e 8px,
                    #e7b883 8px,
                    #e7b883 16px
    );
    border-radius: 5rem;
    transition: 0.4s linear;
    transition-property: width, background-color;
    animation: progressAnimationStrike 3s;
  }
}

@keyframes progressAnimationStrike {
  from {
    width: 0
  }
  to {
    width: 0
  }
}

@for $i from 1 through $howManySteps {
  $stepName: ($i*(100 / $howManySteps));

  .progress-bar-component[data-percentage="#{$stepName}"] {
    .progress-bar-component-progress {
      width: #{$stepName + '%'};
      animation: #{'progressAnimationStrike' + $stepName} 3s;
    }
  }

  @keyframes #{'progressAnimationStrike' + $stepName} {
    from {
      width: 0
    }
    to {
      width: #{$stepName + '%'}
    }
  }
}