@import './../../../../variables';

.dropdown {
  .dropdown-item {
    svg {
      width: 16px;
      height: 16px;
      margin-right: 0.25rem;
    }
  }

  &.dropdown-gradiant {

  }
}
