@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import '../../../../variables';

.page-header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  gap: 2px;
  padding: 10px;
  z-index: 1;

  .page-header-start {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 10px;
    order: 1;
  }

  .page-header-title-container {
    display: flex;
    flex: 2;

    .page-header-title {
      display: inline;
      color: $primaryColor2;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 5px 10px;
      border-radius: 20px;
    }
  }

  .page-header-end {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 0 10px;
    order: 2;
  }

  &.page-header-align-start {
    justify-content: flex-start;
  }

  &.page-header-align-center {
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .page-header-title-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.page-header-align-end {
    justify-content: flex-end;
  }

  @include media-breakpoint-up(sm) {

  }

  @include media-breakpoint-up(md) {

  }

  @include media-breakpoint-up(lg) {
    &.page-header-align-center {
      .page-header-start {
        order: 1;
      }

      .page-header-title-container {
        order: 2;
      }

      .page-header-end {
        order: 3;
      }
    }
  }

  @include media-breakpoint-up(xl) {

  }

  @include media-breakpoint-up(xxl) {

  }
}