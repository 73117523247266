body .main .main-content {
  &.profile {
    .main-page-content-container {
      .page-content {
        justify-content: flex-start;
        margin-top: 2rem;
      }
    }
  }
}

.profile {
  background-color: darkseagreen;

  .main-notification-component {
    max-height: 70vh;

    .main-notification-message-list {
      max-height: 70vh;
    }
  }
}