@import '../../../variables';

.reward-container {
  background: linear-gradient(180deg, $primaryBackgroundColorBlue 0%, $primaryBackgroundColorBlack 100%);

  .reward-items-container-row {
    .reward-items-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px;

      .reward-chest-image {
        max-height: 50vh;
      }

      .reward-items {
        position: absolute;
        width: 100%;
        padding: 10px 0;
        background-color: rgba(0, 6, 129, 0.3);
        border: 1px solid rgba(0, 6, 129, 0.3);
        border-radius: 20px;


        .reward-line {
          //align-items: center;
          justify-content: center;
        }
      }
    }

    .reward-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .btn {
        --bs-btn-padding-y: 0.5rem;
        --bs-btn-padding-x: 1.5rem;
        --bs-btn-font-size: 3rem;
        --bs-btn-border-radius: 3rem;
      }
    }
  }
}

