@import './../../../../variables';

$btnPrimaryColor1: #ff8400;
$btnPrimaryColor2: #b63300;

$btnSecondaryColor1: #002Ddf;
$btnSecondaryColor2: #000681;

.btn {
  & > .svg-inline--fa {
    margin-right: 0.25rem;
  }

  &.btn-rounded {
    --bs-btn-border-radius: 2rem;
  }

  &.btn-primary {
    --bs-btn-color: #{$primaryColor1};
    --bs-btn-hover-color: #{$primaryColor1};
    --bs-btn-active-color: #{$primaryColor1};
    --bs-btn-disabled-color: #{$primaryColor1};
    --bs-btn-border-color: #{$primaryColor2};
    --bs-btn-hover-border-color: #{$primaryColor1};
    background: linear-gradient(180deg, $btnPrimaryColor1 0%, $btnPrimaryColor2 100%);

    &:hover {
      background: linear-gradient(180deg, $btnPrimaryColor2 0%, $btnPrimaryColor1 100%);
    }

    &.btn-gradiant {
      background: #{$primaryColor2};
      background: linear-gradient(205deg, $primaryColor1 20%, $primaryColor2 100%);
      background-repeat: no-repeat; // Fix for firefox 1px of background color displaying on the left
      --bs-btn-color: #fff;
      --bs-btn-hover-color: #fff;
      --bs-btn-active-color: #fff;
      --bs-btn-disabled-color: #fff;
      --bs-btn-border-color: #{$primaryColor2};
      --bs-btn-hover-border-color: #{$primaryColor1};

      &:hover {
        background: #{$primaryColor1};
        background: linear-gradient(205deg, $primaryColor2 20%, $primaryColor1 100%);
        background-repeat: no-repeat; // Fix for firefox 1px of background color displaying on the left
      }
    }
  }

  &.btn-secondary {
    --bs-btn-color: #{$primaryColor2};
    --bs-btn-hover-color: #{$primaryColor1};
    --bs-btn-active-color: #{$primaryColor1};
    --bs-btn-disabled-color: #{$primaryColor2};
    --bs-btn-border-color: #{$btnPrimaryColor1};
    --bs-btn-hover-border-color: #{$primaryColor1};
    background: linear-gradient(180deg, $btnSecondaryColor1 0%, $btnSecondaryColor2 100%);

    &:hover {
      background: linear-gradient(180deg, $btnPrimaryColor2 0%, $btnPrimaryColor1 100%);
    }

    &.btn-gradiant {
      --bs-btn-hover-color: #fff;


      &:hover {
        background: #{$primaryColor1};
        background: linear-gradient(205deg, $primaryColor2 20%, $primaryColor1 100%);
        background-repeat: no-repeat; // Fix for firefox 1px of background color displaying on the left
      }
    }
  }
}
