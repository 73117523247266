.ribbon {
  $default-right: 10px;
  $default-top: -3px;
  $default-color: red;
  $default-width: 32px;
  $default-height: 36px;

  position: absolute;
  right: var(--right, $default-right);
  top: var(--top, $default-top);

  filter: drop-shadow(2px 3px 2px rgba(black, 0.5));

  > .content {
    color: white;
    font-size: 1.25rem;
    text-align: center;
    font-weight: 400;
    background: var(--color, $default-color) linear-gradient(45deg, rgba(black, 0) 0%, rgba(white, 0.25) 100%);
    padding: 8px 2px 4px;

    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);

    width: var(--width, $default-width);
    min-height: var(--height, $default-height);

    transition: clip-path 1s, padding 1s, background 1s;
  }

  &.ribbon-slant-up > .content {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 12px), 50% calc(100% - 6px), 0 100%);
  }

  &.ribbon-slant-down > .content {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 6px), 0 calc(100% - 12px));
  }

  &.ribbon-down > .content {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), 50% 100%, 0 calc(100% - 8px));
  }

  &.ribbon-up > .content {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 8px), 0 100%);
  }

  &.ribbon-check > .content {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), 40% 100%, 0 calc(100% - 12px));
  }

  &.ribbon-success {
    > .content {
      background: var(--color, green) linear-gradient(45deg, rgba(black, 0) 0%, rgba(white, 0.25) 100%);
    }
  }
}