@import '../../../variables';

$validateBtnColorOrange: #ff8400;
$validateBtnColorOrange2: #b63300;

$progressBarHeight: 60px;

$buttonSize: (60px + 30px);

.challenge-list-container {
  background: linear-gradient(180deg, $primaryBackgroundColorBlue 0%, $primaryBackgroundColorBlack 100%);

  .challenge-remaining-time-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    color: $primaryColor2;
  }

  .challenge-success-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    color: $primaryColor2;
    font-size: 1.5rem;
  }

  .challenge-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    max-width: 100%;

    .challenge-card {
      position: relative;
      display: block;
      width: 100%;
      max-width: 500px;
      margin-bottom: 50px;

      .card-label {
        text-align: center;
        color: white;
      }

      .card-content {
        position: relative;
        text-align: center;

        .progress-bar-component {
          height: $progressBarHeight;
        }
      }

      .challenge-validated-label {
        position: absolute;
        top: calc(50% - ($buttonSize / 2));
        right: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $buttonSize;
        height: $buttonSize;
        color: white;
        background-color: $primaryBackgroundColorRoyalBlue;
        border: 2px solid white;
        border-radius: 50%;

        .svg-inline--fa {
          width: 50%;
          height: 50%;
        }
      }

      &.challenge-card-simple {
        min-height: $progressBarHeight;
        border: 1px solid white;
        border-radius: 50px;
        padding: 5px 30px;
        background: rgba(0, 0, 0, 0.25);

        .challenge-btn-simple {
          margin: 10px 0 (-$progressBarHeight / 2) 0;
        }
      }

      &.challenge-card-multiple {
        .challenge-btn-multiple {
          position: absolute;
          top: calc(50% - ($buttonSize / 2));
          right: -10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: $buttonSize;
          height: $buttonSize;
          border-radius: 50%;
        }
      }

      .card-footer {
        //height: 20px;
        padding: 0;

        .challenge-progress-bar {
          border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .challenge-back {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
  }
}

// Progress
$h: .5em;
$r: .5*$h;
$b: .07em;

@mixin val() {
  //border-radius: $r - $b;
  border-radius: 0;
  box-shadow: inset 0 .05em .05em rgba(#fff, .35);
  background: var(--fill)
}

progress {
  box-sizing: border-box;
  //border: solid $b #242b35;
  //border: solid .03em  #242b35;
  border: solid 0 #242b35;
  width: 100%;
  //height: $h;
  height: 20px;
  //border-radius: $r;
  border-radius: 0;
  background: linear-gradient(#191c23, #2d3341);
  font: clamp(.625em, 7.5vw, 5em) monospace;
  --fill: linear-gradient(#{rgba(#15ba08, .65)}, transparent),
  repeating-linear-gradient(135deg,
                  #0e7006 0 #{.5*$r}, #118c07 0 #{$r});

  &::-webkit-progress-bar {
    background: transparent
  }

  &::-webkit-progress-value {
    @include val()
  }

  &::-moz-progress-bar {
    @include val()
  }

  &:nth-child(1) {

  }
}