@import '../../../../variables';

.player-avatar {
  display: flex;
  align-items: center;
  justify-content: center;

  .player-avatar-container {
    position: relative;
  }

  .player-avatar-image {
    border: 0.125rem solid #{$primaryColor1};
    border-radius: 50rem;
    overflow: hidden;
  }

  .player-avatar-leader {
    position: absolute;
    top: 2%;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    color: #{$primaryColor1};

    svg {
      filter: drop-shadow(0 0 1px #{$primaryBackgroundColorRoyalBlue});
    }
  }

  .player-avatar-level {
    position: absolute;
    top: 2%;
    right: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    .badge {
      padding: 0.125rem 0.25rem;
    }
  }
}
