@import '../../../../variables';

.player-card {
  cursor: pointer;

  .player-card-avatar {
    display: flex;
    align-items: center;
    justify-content: center;




    .player-card-avatar-image {
      position: relative;

      .player-card-avatar-img {
        border: 0.125rem solid transparent;
        border-radius: 50rem;
        overflow: hidden;
      }

      .player-card-avatar-leader {
        position: absolute;
        top: 0;
        left: 0;
      }

      .player-card-avatar-level {
        position: absolute;
        top: 0;
        right: 0;
      }
    }




  }

  .player-card-pseudo {
    text-align: center;
  }

  //&.player-card-avatar-is-logged-user {
  //  //background-color: #FDD188;
  //
  //  .player-card-avatar {
  //    .player-card-avatar-image {
  //      .player-card-avatar-img {
  //        border-color: #{$primaryColor2};
  //      }
  //
  //      .player-card-avatar-leader {
  //        color: #{$primaryColor2};
  //      }
  //    }
  //  }
  //}

  &:hover {
    border-color: #{$primaryColor2};
  }

  &.active {
    background: #{$primaryColor1};
    background: linear-gradient(205deg, $primaryColor2 20%, $primaryColor1 100%);
    background-repeat: no-repeat; // Fix for firefox 1px of background color displaying on the left
    border-color: #{$primaryColor2};
    cursor: default;

    .player-avatar {
      .player-avatar-image {
        .player-card-avatar-img {
          border-color: #{$primaryColor1};
        }

        .player-card-avatar-leader {
          color: #{$primaryColor1};
        }
      }
    }

    .player-card-pseudo {
      //color: #{$primaryColor2};
    }
  }
}