@import '../../../../variables';

body .main .main-content {
  &.adventurer-group {
    .main-page-content-container {
      .page-content {
        justify-content: flex-start;
        margin-top: 2rem;
      }
    }
  }
}
