$primaryColor1: #000681;
$primaryColor2: #ff8400;

$primaryBackgroundColorBlue: #0400b3;
$primaryBackgroundColorRoyalBlue: #002DFA;
$primaryBackgroundColorBlack: #000000;

$btnPrimaryColor1: #ff8400;
$btnPrimaryColor2: #b63300;

$darkGrey: #535358;
$lightGrey: #9f9fa5;




// Bootstrap
$primary: $primaryColor2;