@import '../../../../variables';

.list-group {
  .list-group-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-bottom: var(--bs-list-group-border-width) solid $primaryColor2;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;

    .list-group-header-item {
      .list-group-header-item-content {

      }
    }
  }

  .list-group-item {
    color: #9f9fa5;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    border-bottom: var(--bs-list-group-border-width) solid $lightGrey;

    .list-group-item-container {
      align-items: center;

      .list-group-item-column-actions {
        .list-group-item-actions {

        }
      }
    }

    .list-group-item-avatar {
      margin: 0.25rem;

      .list-group-item-avatar-image {
        width: 50px;
        height: 50px;
        border: 0.125rem solid #{$darkGrey};
        border-radius: 50rem;
        object-fit: cover;
        overflow: hidden;
      }
    }

    .list-group-item-avatar-pseudo {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .list-group-item-pseudo {
        margin: 0.25rem;
      }
    }
  }
}