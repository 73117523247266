@import '../../../variables';

body {
  .main {
    .main-content {
      &.user {
        background: rgb(9,9,121);
        background: linear-gradient(135deg, $primaryColor1 0%, $primaryColor2 100%);

        .main-background-image {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          margin: auto;
        }

        .main-overlay-content {
          .main-page-content-container {
            .page-content {
              justify-content: space-between;

              .block1 {

              }

              .block2 {
                color: #fff;
                text-align: center;

                .loading {
                  margin-top: 210px;
                }

                .main-title {
                  margin-top: 250px;

                  .main-title-first {
                    display: block;
                    font-size: 2.5rem;
                  }

                  .main-title-second {
                    display: block;
                    padding: 25px 10px 10px 10px;
                    line-height: 3rem;
                    font-size: 3rem;
                    font-family: 'EmpoweredStylized', sans-serif;
                  }
                }
              }
              .block3 {
                padding-bottom: 30px;

                .btn {

                }
              }
            }
          }
        }
      }
    }
  }
}