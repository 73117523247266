$cardBorderRadius: 0.375rem;


.main-action-card-component {
  --app-card-color: black;
  position: relative;
  max-width: 180px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.25);
  border: 2px solid var(--app-card-color);
  border-radius: $cardBorderRadius;
  cursor: pointer;
  filter: drop-shadow(0px 0px 5px var(--app-card-color));

  .main-action-card-header {
    margin: 0;
    padding: 2px;
    text-align: center;
    color: var(--app-card-color);
    background-color: white;
  }

  .main-action-card-img-container {
    padding: 30px;
    border-top-left-radius: $cardBorderRadius;
    border-top-right-radius: $cardBorderRadius;
    border-bottom: 2px solid var(--app-card-color);

    .main-action-card-img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100px;
      max-height: 100px;
      margin: auto;
    }
  }

  .main-action-card-title {
    margin: 0;
    padding: 2px;
    text-align: center;
    color: var(--app-card-color);
    background-color: white;
    border-bottom-left-radius: $cardBorderRadius;
    border-bottom-right-radius: $cardBorderRadius;
    font-size: 1.5rem;
    line-height: 1.5rem;
    overflow: hidden;
  }

  &.main-action-card-color-darkblue {
    --app-card-color: darkblue;
  }

  &.main-action-card-color-green {
    --app-card-color: green;
  }

  &.main-action-card-color-purple {
    --app-card-color: purple;
  }

  &.main-action-card-color-red {
    --app-card-color: red;
  }

  &.main-action-card-color-orange {
    --app-card-color: orange;
  }

  &.main-action-card-building {
    //--app-card-color: grey;
    cursor: not-allowed;

    .main-action-card-header {
      color: white;
      background-color: var(--app-card-color);
    }

    &:hover {
      box-shadow: none;

      .main-action-card-title {
        color: var(--app-card-color);
        background-color: white;
      }
    }
  }

  &:hover {
    box-shadow: 0 -4px 20px var(--app-card-color);

    .main-action-card-title {
      color: white;
      background-color: var(--app-card-color);
    }
  }
}