@import '../../../../variables';

$headerBlueColor: #02004c;
$headerBlackColor: #000000;

$notificationListZIndex: 100;

$notificationListCloseButtonSize: 50px;

:root {
  overflow: hidden;
  height: 100%;
}

.main-notification-component {
  position: relative;
  background: rgba(255, 255, 255, 0.25);
  z-index: $notificationListZIndex;

  .main-notification-close-container {
    position: absolute;
    top: (-$notificationListCloseButtonSize / 2);
    left: (-$notificationListCloseButtonSize / 2);
    z-index: $notificationListZIndex + 1;
  }

  .main-notification-message-list {
    display: block;
    height: 100%;
    overflow-y: auto;

    .main-notification-message {
      display: flex;
      align-items: center;
      padding: 5px;

      .main-notification-message-image {
        position: relative;
        max-width: 100px;

        .main-notification-message-img {
          object-fit: cover;
          max-width: 100%;
          background-color: $primaryColor1;
          border: 5px solid $primaryColor2;
          border-radius: 50%;
          z-index: 2;
        }
      }

      .main-notification-message-content {
        margin: 0 0 0 -50px;
        padding: 2px 5px 2px 50px;
        background-color: white;
        border: 1px solid black;
        border-radius: 10px;

        .main-notification-message-content-title {
          color: $primaryColor2;
          margin-bottom: 0.25rem;
        }

        .main-notification-message-content-body {
          color: black;
        }
      }
    }
  }

  &.main-notification-component-hidden {
    display: none;

    &.main-notification-component-forced-displayed {
      display: block;
    }
  }

  &.main-notification-component-forced-hidden {
    display: none;
  }
}

.main {
  & > .main-notification-component {
    position: absolute;
    top: 100px;
    right: 0;
    width: 400px;
    height: calc(100% - 100px);
  }
}