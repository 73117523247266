@import '../../../../variables';

.user-coach-container {
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 10;

  .user-coach {
    position: relative;
    display: flex;

    .user-coach-image {
      width: 200px;
      height: 200px;
      object-fit: cover;
      max-width: 100%;
      background-color: $primaryColor1;
      border: 5px solid $primaryColor2;
      border-radius: 50%;
      z-index: 2;
    }

    .user-coach-message-container {
      //position: absolute;
      //top: 0;
      //right: 200px;
      z-index: 1;

      .user-coach-message {
        position: relative;
        margin: 40px -50px 0 0;
        padding: 5px 50px 5px 5px;
        color: black;
        background-color: white;
        border: 1px solid black;
        border-radius: 10px;

        //&::after {
        //  content: " ";
        //  position: absolute;
        //  top: 10%;
        //  left: 100%;
        //  margin-right: -5px;
        //  border-width: 5px;
        //  border-style: solid;
        //  border-color: transparent transparent transparent white;
        //}

        .user-coach-message-label {
          margin-bottom: 5px;
        }

        .user-coach-message-button {
          text-align: right;
        }
      }
    }
  }
}