@import './variables';

@import "~bootstrap";




/*.main {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  width: 100vw;*/
/*  height: 100vh;*/
/*  background-color: black;*/
/*}*/

/*!* Content *!*/
/*.main .main-content {*/
/*  position: relative;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  overflow: hidden*/
/*}*/
/*.main .main-content .main-image {*/
/*  flex-shrink: 0;*/
/*  min-width: 100%;*/
/*  min-height: 100%;*/
/*  max-width: 100%;*/
/*  max-height: 100%;*/
/*}*/
/*.main .main-content .main-overlay {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  max-width: 100%;*/
/*  max-height: 100%;*/
/*}*/
/*.main .main-content .main-overlay .Header {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  height: 80px;*/
/*  padding: 0;*/
/*  text-align: center;*/
/*}*/
/*.main .main-content .main-overlay .Header .main-title {*/
/*  padding: 15px 15px;*/
/*  color: green;*/
/*  background-color: rgba(255, 255, 255, 0.6);*/
/*  border-radius: 10px;*/
/*  text-shadow: 1px 1px 1px grey;*/
/*}*/
/*.main .main-content .main-overlay .main-back {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 10px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  height: 80px;*/
/*}*/
/*.main .main-content .main-overlay .main-back .btn {*/
/*  font-size: 20px;*/
/*}*/

/* Card */





.main-card-action {
  --app-card-color: black;
  position: relative;
  max-width: 180px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.25);
  border: 2px solid var(--app-card-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  filter: drop-shadow(0px 0px 5px var(--app-card-color));

  .card-counter {
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px 0 6px;
    color: white;
    background-color: red;
    border: 1px solid black;
    border-radius: 50%;

    &.card-counter-success {
      background-color: green;
    }
  }

  .main-card-header {
    margin: 0;
    padding: 2px;
    text-align: center;
    color: var(--app-card-color);
    background-color: white;
  }

  .main-card-img {
    border-bottom: 2px solid var(--app-card-color);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .main-card-title {
    margin: 0;
    padding: 2px;
    text-align: center;
    color: var(--app-card-color);
    background-color: white;
    border-bottom-left-radius: var(--bs-card-border-radius);
    border-bottom-right-radius: var(--bs-card-border-radius);
    overflow: hidden;
  }

  &.card-color-darkblue {
    --app-card-color: darkblue;
  }

  &.card-color-green {
    --app-card-color: green;
  }

  &.card-color-purple {
    --app-card-color: purple;
  }

  &.card-color-red {
    --app-card-color: red;
  }

  &.card-color-orange {
    --app-card-color: orange;
  }

  &:hover {
    box-shadow: 0 -4px 20px var(--app-card-color);

    .main-card-title {
      color: white;
      background-color: var(--app-card-color);
    }
  }

  &.main-card-action-in-construction {
    --app-card-color: grey;
    cursor: not-allowed;

    .main-card-header {
      color: white;
      background-color: grey;
    }

    &:hover {
      box-shadow: none;

      .main-card-title {
        color: var(--app-card-color);
        background-color: white;
      }
    }
  }
}

/* Button */
/*.btn {*/
/*  display: flex;*/
/*  color: white;*/
/*  background-color: green;*/
/*  border: 1px solid black;*/
/*  padding: 5px 10px;*/
/*  border-radius: 5px;*/
/*  text-shadow: 1px 1px 1px grey;*/
/*  cursor: pointer;*/
/*}*/
/*.btn:hover {*/
/*  color: black;*/
/*  border-color: black;*/
/*}*/