@import '../../../../variables';

.coach-player-list-component {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.375rem;

  .coach-player-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem;

    .coach-player-list-header-left {
      display: flex;
    }

    .coach-player-list-header-right {

    }
  }

  .coach-player-list-title-container {
    margin: 0.25rem;

    .coach-player-list-title {
      margin: 0;
    }
  }

  .coach-player-list-search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0.25rem 0.25rem 0.25rem 1rem;

    .coach-player-list-search-form-group {
      .coach-player-list-search-form-control {
        --bs-border-radius: 2rem;
      }

      .btn {

      }
    }
  }

  .coach-player-list-main-actions {
    margin: 0.25rem;
  }

  .coach-player-list-container {
    margin-top: 1rem;
  }
}
